import usePartySocket from "partysocket/react";
import { useEffect, useState } from "react";
import { Button } from "../components/Button/Button";
import { testRecording } from "../fixtures/test-recording";
import { getEndpoint } from "./utils/getEndpoint";
import { roomId } from "./utils/roomId";

let testRecordingLog: any[] = [];

type Props = {
  appToken: string;
  deepgramToken: string;
};

const RecorderInterface: React.FC<Props> = ({ appToken, deepgramToken }) => {
  const endpoint = getEndpoint();

  const [isRecording, setIsRecording] = useState(false);
  const [language, setLanguage] = useState<
    "en" | "en-GB" | "en-US" | "en-NZ" | "de"
  >("en");
  const sessionId = "current-session-id";
  const [isRelayConnected, setIsRelayConnected] = useState(false);
  const [isTranscriberConnected, setIsTranscriberConnected] = useState(false);
  const partykitSocket = usePartySocket({
    room: roomId,
    onOpen() {
      // console.log("Partykit Opened");
      setIsRelayConnected(true);
    },
    onClose() {
      // console.log("Partykit Closed");
      setIsRelayConnected(false);
    },
    onError() {
      console.log("Partykit Error");
    },
    query: async () => {
      return { token: appToken }; // attach the token to the query string
    },
  });

  const transformAndSendMessage = (received: any) => {
    const transcript = received.channel.alternatives[0].transcript;
    if (transcript && received.is_final) {
      // document.querySelector("#transcript").textContent = transcript;
      if (partykitSocket) {
        partykitSocket.send(
          JSON.stringify({
            type: "final-text",
            text: transcript,
            sessionId,
          })
        );
      }
    } else if (transcript) {
      if (partykitSocket) {
        partykitSocket.send(
          JSON.stringify({
            type: "draft-text",
            text: transcript,
            sessionId,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!isRecording) return;

    console.log("start recording");

    let mediaRecorder: MediaRecorder;
    let socket: WebSocket;

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorder = new MediaRecorder(stream);
      socket = new WebSocket(
        // API reference: https://developers.deepgram.com/reference/listen-live
        `wss://api.deepgram.com/v1/listen?interim_results=true&punctuate=true&language=${language}`,
        ["token", deepgramToken]
      );

      socket.onopen = () => {
        // console.log({ event: "onopen" });
        // document.querySelector("#status").textContent = "Connected";
        mediaRecorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0 && socket.readyState == 1) {
            socket.send(event.data);
          }
        });
        mediaRecorder.start(100);
        setIsTranscriberConnected(true);
      };

      socket.onmessage = (message) => {
        // console.log({ event: "onmessage", message });
        const received = JSON.parse(message.data);

        // log testRecordingLog
        // testRecordingLog = [...testRecordingLog, received];
        // console.log(JSON.stringify(testRecordingLog));

        transformAndSendMessage(received);
      };

      socket.onclose = () => {
        setIsTranscriberConnected(false);
        console.log({ event: "onclose" });
      };

      socket.onerror = (error) => {
        console.log({ event: "onerror", error });
      };
    });

    return () => {
      mediaRecorder?.stop();
      socket?.close();
    };
  }, [isRecording]);

  return (
    <>
      <p>
        Relay connection:{" "}
        {isRelayConnected ? "🟢 connected" : "❌ disconnected"}
      </p>
      <p>
        Transcriber connection:{" "}
        {isTranscriberConnected ? "🟢 connected" : "❌ disconnected"}
      </p>
      <label>
        Language
        <select
          disabled={isRecording}
          value={language}
          onChange={(e) => {
            // @ts-expect-error fix it by parsing the value
            setLanguage(e.target.value);
          }}
        >
          <option value="en">English</option>
          <option value="en-GB">English (UK)</option>
          <option value="en-US">English (US)</option>
          <option value="en-NZ">English (NZ)</option>
          <option value="de">German</option>
        </select>
      </label>
      <br />
      <Button
        disabled={isRecording}
        onClick={() => {
          setIsRecording(true);
        }}
      >
        Record
      </Button>
      <Button
        disabled={!isRecording}
        onClick={() => {
          setIsRecording(false);
        }}
      >
        Stop Recording
      </Button>

      <br />
      <br />
      <br />
      <Button
        onClick={async () => {
          // partykitSocket.send("Test data");
          for (const transcript of testRecording) {
            await new Promise((resolve) => setTimeout(resolve, 50));
            // console.log(transcript);
            transformAndSendMessage(transcript);
          }
        }}
      >
        Send Test data to server
      </Button>
      <Button
        onClick={async (event) => {
          try {
            const result = await fetch(`${endpoint}/parties/main/${roomId}`, {
              headers: { Authorization: appToken },
              body: JSON.stringify({ type: "reset-session" }),
              method: "POST",
            });
            const { status } = await result.json();
            if (status === "ok") {
              alert("Successfully reset the session");
            }
          } catch (error) {
            alert("Failed to reset the session");
          }
        }}
      >
        Reset Session (will delete the current content on the server and for all
        current subscribers)
      </Button>
    </>
  );
};

export default RecorderInterface;
