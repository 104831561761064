import usePartySocket from "partysocket/react";
import { useEffect, useRef, useState } from "react";
import { roomId } from "../recorder/utils/roomId";

const Subscriber: React.FC = () => {
  const [isRelayConnected, setIsRelayConnected] = useState(false);
  const [text, setText] = useState("");
  const [draftText, setDraftText] = useState("");
  usePartySocket({
    room: roomId,
    onMessage(evt) {
      const data = JSON.parse(evt.data);
      console.log("data", data);
      if (data.type === "draft-text") {
        setDraftText(data.text);
      } else if (data.type === "final-text") {
        setDraftText("");
        setText((currentText) => currentText + data.text + " ");
      } else if (data.type === "session") {
        // @ts-expect-error
        const initialText = data.session.map((rawMessage) => {
          const message = JSON.parse(rawMessage);
          if (message.type === "final-text") {
            return message.text;
          }
          return "";
        });
        setText((currentText) => initialText.join(" ") + " " + currentText);
      }
    },
    onOpen() {
      // console.log("Partykit Opened");
      setIsRelayConnected(true);
    },
    onClose() {
      // console.log("Partykit Closed");
      setIsRelayConnected(false);
    },
    onError() {
      console.log("Partykit Error");
    },
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const textParagraphRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (
      textContainerRef.current &&
      scrollContainerRef.current &&
      textParagraphRef.current
    ) {
      // TODO calculate the actual font size
      const fontSize = 40;
      if (
        textParagraphRef.current.clientHeight + fontSize >
        textContainerRef.current.clientHeight
      ) {
        console.log(
          "textParagraphRef.current.clientHeight",
          textParagraphRef.current.clientHeight
        );
        console.log(
          "textContainerRef.current.clientHeight",
          textContainerRef.current.clientHeight
        );

        const newPageHeight = scrollContainerRef.current.clientHeight * 0.75;
        const newHeight = textContainerRef.current.clientHeight + newPageHeight;
        console.log("set textContainerRef minHeight: ", newHeight);
        textContainerRef.current.style.minHeight = `${newHeight}px`;
        scrollContainerRef.current.scrollBy({
          left: 0,
          top: textContainerRef.current.clientHeight,
          behavior: "smooth",
        });
        console.log("scroll to: ", textContainerRef.current.clientHeight);
      }
    }
  });

  return (
    <>
      <div
        style={{
          height: "5vh",
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        }}
      >
        Connection status:{" "}
        {isRelayConnected ? "🟢 connected" : "❌ disconnected"}
      </div>
      <div
        style={{
          display: "block",
          height: "95vh",
          overflowY: "scroll" /* enable vertical scrolling */,
          borderRadius: "1px solid #ccc",
        }}
        ref={scrollContainerRef}
      >
        <div ref={textContainerRef}>
          <p
            ref={textParagraphRef}
            style={{
              padding: 40,
              fontSize: 24,
            }}
          >
            {text} <span style={{ color: "gray" }}>{draftText}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Subscriber;
