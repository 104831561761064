import { useState } from "react";
import { Button } from "../components/Button/Button";
import { getEndpoint } from "./utils/getEndpoint";

type Props = {
  onAuthenticated: (result: {
    appToken: string;
    deepgramToken: string;
  }) => void;
};

const Login: React.FC<Props> = ({ onAuthenticated }) => {
  const [appToken, setAppToken] = useState("");

  const endpoint = getEndpoint();

  return (
    <>
      <p>Please provide the Recorder App Token:</p>
      <form>
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(event) => {
            setAppToken(event.target.value);
          }}
          value={appToken}
        />
        <Button
          disabled={appToken === ""}
          onClick={async (event) => {
            event.preventDefault();
            try {
              const result = await fetch(
                `${endpoint}/parties/main/authenticate`,
                {
                  headers: {
                    Authorization: appToken,
                  },
                  method: "POST",
                }
              );
              const { deepgramToken } = await result.json();

              onAuthenticated({ appToken, deepgramToken });
            } catch (error) {
              alert("Invalid App Token");
            }
          }}
        >
          Login
        </Button>
      </form>
      <p>API Endpoint: {endpoint}</p>
    </>
  );
};

export default Login;
