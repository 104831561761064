import { createRoot } from "react-dom/client";
import Recorder from "./recorder/Recorder";
import "./styles.css";
import Subscriber from "./subscriber/Subscriber";

if (window.location.hash === "#recorder") {
  createRoot(document.getElementById("app")!).render(<Recorder />);
} else {
  createRoot(document.getElementById("app")!).render(<Subscriber />);
}
