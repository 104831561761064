import { useState } from "react";
import Login from "./Login";
import RecorderInterface from "./RecorderInterface";

const Recorder: React.FC = () => {
  const [tokens, setTokens] = useState({ appToken: "", deepgramToken: "" });

  return (
    <>
      {tokens.appToken === "" ? (
        <Login
          onAuthenticated={(newTokens) => {
            setTokens(newTokens);
          }}
        />
      ) : (
        <RecorderInterface
          appToken={tokens.appToken}
          deepgramToken={tokens.deepgramToken}
        />
      )}
    </>
  );
};

export default Recorder;
